import { Key as MineIcon } from '@mui/icons-material';
import {
  AvatarGroup,
  Box,
  ButtonBase,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import type { Group, PaginatedList, Person } from 'core';
import type { FC } from 'react';
import uri from 'uri-tag';
import { PersonAvatar } from '../../components/entity/person.js';

interface Props {
  data: PaginatedList<Group.Type>;
  person: Person.Type;
}

export const ListItemsView: FC<Props> = ({
  data: { items, matches },
  person,
}) => (
  <Stack spacing={3}>
    {items.map((item) => (
      <ButtonBase
        key={item._id}
        sx={{ justifyContent: 'stretch', textAlign: 'start' }}
        href={uri`#/group/${item._id}`}
      >
        <Card sx={{ width: '100%' }}>
          <CardContent>
            <Stack spacing={1}>
              <Box display="flex" flex={1} justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                  <Typography variant="h3">{item.title}</Typography>
                  {item.admin?.personIds?.length ? (
                    <AvatarGroup>
                      {item.admin?.personIds?.map((personId) => (
                        <PersonAvatar
                          key={personId}
                          personId={personId}
                          size="1.25em"
                        />
                      ))}
                    </AvatarGroup>
                  ) : null}
                  {item.members?.length ? (
                    <AvatarGroup>
                      {item.members?.map((personId) => (
                        <PersonAvatar
                          key={personId}
                          personId={personId}
                          size="1.25em"
                        />
                      ))}
                    </AvatarGroup>
                  ) : null}
                </Stack>
                <Stack spacing={1} direction="row">
                  {item.admin?.personIds?.includes(person._id) && <MineIcon />}
                </Stack>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </ButtonBase>
    ))}
  </Stack>
);
