import axios from 'axios';
import type { Group, PaginatedList } from 'core';
import type { FC } from 'react';
import usePromise from 'react-use-promise';
import uri from 'uri-tag';
import { useAuthenticated } from '../../auth/auth-provider.js';
import { GroupListPageView } from './view.js';

const Controller: FC = () => {
  const { person } = useAuthenticated();

  const groups = usePromise(async () => {
    const { data } = await axios.get<PaginatedList<Group.Type>>(
      uri`/api/v1/my/groups`,
      {
        params: { sort: 'title' },
      },
    );
    return data;
  }, []);

  return GroupListPageView({
    groups,
    person,
  });
};

export default Controller;
