import { Stack, Typography } from '@mui/material';
import type { FC } from 'react';

export const NoItemsView: FC = () => (
  <Stack spacing={2}>
    <Typography variant="body2" color="text.secondary" fontStyle="italic">
      No groups could be found.
    </Typography>
    <Typography variant="body2" color="text.secondary" fontStyle="italic">
      Groups can be used to manage access to non-public events.
    </Typography>
  </Stack>
);
